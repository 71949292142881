export const formatTags = (tags) => {
  return !Array.isArray(tags) ? [tags] : tags;
};

export const formatActions = (actions) => {
  return !Array.isArray(actions) ? [actions] : actions;
};

export const getUserAgent = () => window.navigator.userAgent;

export const getReferrer = () => document.referrer;

export const getUrl = () => window.location.href;
