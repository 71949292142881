import axios from "axios";
import cookie from "js-cookie";
import { addMinutes, addYears } from "date-fns";
import { formatActions, formatTags, getUserAgent, getReferrer, getUrl } from "./helpers";

class TrackingClient {
  options = {
    baseUrl: "http://converted-tracking-api.local/track",
    trackingTTL: addYears(new Date(), 2),
    sessionTTL: addMinutes(new Date(), 30),
    trackingName: "tc",
    sessionName: "sc"
  };

  pageLoadId = null;

  constructor(accountId, propertyId, verification, options = {}) {
    const defaultOptions = this.options;

    this.accountId = accountId;
    this.propertyId = propertyId;
    this.verification = verification;
    this.options = { ...defaultOptions, ...options };
    this.request = axios.create();
  }

  get trackingId() {
    const { trackingName } = this.options;

    return cookie.get(trackingName) !== undefined ? cookie.get(trackingName) : null;
  }

  set trackingId(trackingId) {
    const { trackingName, trackingTTL } = this.options;

    cookie.set(trackingName, trackingId, { expires: trackingTTL });
  }

  get sessionId() {
    const { sessionName } = this.options;

    return cookie.get(sessionName) !== undefined ? cookie.get(sessionName) : null;
  }

  set sessionId(sessionId) {
    const { sessionName, sessionTTL } = this.options;

    cookie.set(sessionName, sessionId, { expires: sessionTTL });
  }

  async init(actions = [], tags = []) {
    this.initRequest = this.request.post(this.options.baseUrl, {
      "property_id": this.propertyId,
      "account_id": this.accountId,
      "verification": this.verification,
      "session_id": this.sessionId,
      "tracking_id": this.trackingId,
      "user_agent": getUserAgent(),
      "referrer": getReferrer(),
      "url": getUrl(),
      "actions": formatActions(actions),
      "tags": formatTags(tags)
    });

    const response = await this.initRequest;
    const data = response.data;

    this.sessionId = data.session_id;
    this.trackingId = data.tracking_id;
    this.pageLoadId = data.page_load_id;
  }

  async pushTags(tags = []) {
    tags = formatTags(tags);

    if (!this.initRequest) {
      await this.init();
    }

    await this.initRequest;

    const response = await this.request.post(this.options.baseUrl, {
      "property_id": this.propertyId,
      "account_id": this.accountId,
      "verification": this.verification,
      "session_id": this.sessionId,
      "tracking_id": this.trackingId,
      "page_load_id": this.pageLoadId,
      "user_agent": getUserAgent(),
      "referrer": getReferrer(),
      "url": getUrl(),
      tags
    });

    const data = response.data;

    this.sessionId = data.session_id;
    this.trackingId = data.tracking_id;
    this.pageLoadId = data.page_load_id;
  }

  async pushActions(actions = []) {
    actions = formatActions(actions);

    if (!this.initRequest) {
      await this.init();
    }

    await this.initRequest;

    const response = await this.request.post(this.options.baseUrl, {
      "property_id": this.propertyId,
      "account_id": this.accountId,
      "verification": this.verification,
      "session_id": this.sessionId,
      "tracking_id": this.trackingId,
      "page_load_id": this.pageLoadId,
      "user_agent": getUserAgent(),
      "referrer": getReferrer(),
      "url": getUrl(),
      actions
    });

    const data = response.data;

    this.sessionId = data.session_id;
    this.trackingId = data.tracking_id;
    this.pageLoadId = data.page_load_id;
  }
}

export default TrackingClient;
